import { useEffect } from "react";
import "./App.css";

const TITLE = "Constellation Arms Co.";

function App() {
  useEffect(() => {
    document.title = TITLE;
  });

  return (
    <div className="App">
      <div className="content">
        Coming soon... <span className="blink">|</span>
      </div>
    </div>
  );
}

export default App;
